<template>
  <div :style="cssVars">
    <v-layout v-resize="onResize">
    <v-main id="pages" :style="'color:' + textColour + '; background-color:' + backgroundColour">
      <v-img :src="backgroundImage.url" min-height="100%" :class="{
          'v-image--sm': this.$vuetify.breakpoint.smAndDown,
          'v-image--md': this.$vuetify.breakpoint.mdAndDown
        }">
          <Toolbar :boxOffice="boxOffice"/>
          <div align ="right">
            <checkoutButton @click="checkout=true" :order="order"/>
          </div>
          <v-responsive :style="styles" min-height="85vh" class="fill-height justify-center">
            <div v-if="boxOffice">
              <div class="mx-auto">
                <cld-image v-if="boxOffice.logoImage" style="display:table" class="mx-auto" :publicId="boxOffice.logoImage"/>
                <pages-heading v-else class="text-center mb-5">{{boxOffice.name}}</pages-heading>
                <div class="mt-2">
                  <cld-image class="mx-auto" style="display:table" :publicId="boxOffice.boxOfficeImage" :width="maxImageWidth" crop="limit"/> 
                  <span class="ma-2" v-html="boxOffice.description"/>
                </div>
              </div>
              <div class="mx-auto" style="max-width:380px; font-family:Montserrat, sans-serif">
                <queue v-if="queued" @queued="queueUpdate"/>
                <div v-else-if="loaded && events.length < 1"><v-card-title>Sorry, no published events</v-card-title></div>
                <div v-for="(event, i) in events" :key="i"> 
                  <v-row align="center">
                    <v-col cols="8"><h3>{{event.name}}</h3>
                      <div v-if="!event.hideDatesOnBoxOfficePage">
                        <div v-if="utils.sameDay(event.eventTime.start, event.eventTime.end)">
                          {{utils.dateToNiceDay(event.eventTime.start)}}
                          {{utils.dateToNiceTime(event.eventTime.start)}} until {{utils.dateToNiceTime(event.eventTime.end)}}
                        </div>
                        <div v-else-if="event.eventTime.start || event.eventTime.end">
                          {{utils.dateToNiceString(event.eventTime.start)}} to {{utils.dateToNiceString(event.eventTime.end)}}
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="4" class="d-flex" style="align-items: right;"><v-spacer /><Button @click="buyTickets(event)">Tickets</Button></v-col>
                  </v-row>
                  <v-row v-if="!event.published">
                    <v-col cols="12" style="color: red">This event has not been published - only you can see it</v-col>
                  </v-row>
                  <v-row v-if="event.thumbnail">
                    <v-col cols="12"><cld-image :publicId="event.thumbnail" width="274" crop="scale"/> </v-col>
                  </v-row>
                  <v-row v-if="event.summary">
                    <v-col cols="12" class="mt-3">{{event.summary}}</v-col>
                  </v-row>
                  <v-row class="mb-2">
                    <v-col cols="12"><hr style="height:1px;border-width:0;color:white;background-color:white"/></v-col>
                  </v-row>
                </div>
              </div>
            </div>
            <div v-else>
              <v-main>
                <v-container>
                <v-row align="center" justify="center">
                    <v-col class="text-center"><pages-heading>404 - Not Found</pages-heading>
                    </v-col>
                  </v-row>
                </v-container>
              </v-main>
            </div>
          </v-responsive>
        </v-img>
      <contact :boxOffice="boxOffice"/>
    </v-main>
</v-layout>

    <checkout v-if="checkout" :boxOffice="boxOffice" @closed="checkout=false" @closedConfirmed="checkout=false"/>

  </div>
</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js'
  import Store from '@/services/Store.js';
  export default {
    components: {
      Checkout: () => import('./Checkout'),
      Queue: () => import('./Queue'),
      Contact: () => import('./Contact'),
      PagesHeading: () => import('../pages/components/Heading'),
      EventTimeDisplay: () => import('../dashboard/components/EventTimeDisplay'),
      CheckoutButton: () => import('./CheckoutButton'),
      Button: () => import('./Button'),
      Toolbar: () => import('./Toolbar'),
    },  

    metaInfo() {
        return this.meta;
    },

    data () {
      return {
        meta:  {
          title: "Happy Tickets"
        },
        textColour: null,
        backgroundColour: null,
        boxOffice: {},
        events: [],
        eventTimes: {},
        backgroundImage: {},
        backgroundImageUrl: "",
        order: Store.order,
        maxImageWidth: 600,
        checkout: false,
        loaded: false,
        queued: false,
      }
    },
    
    async created() {
//      this.$vuetify.theme.dark = true
      this.boxOffice.path = this.$route.params.boxOfficePath;
      if (this.boxOffice.path == "SamRyder") {
        this.queued = true
        this.boxOffice.logoImage = "http://res.cloudinary.com/happytickets/image/upload/v1/X8nWSm171w/SAM_RYDER_PNG-_small_white_qfymrx"
        this.backgroundColour = "#0394b8FF"
        this.textColour = "#FFFFFFFF"
      } 
      if (this.boxOffice.path == "OnlyThePoets") {
        this.queued = true
        this.boxOffice.logoImage = "https://res.cloudinary.com/happytickets/image/upload/v1/STQQ615wFI/new_happy_tickets_otp_u1kizz"
        this.backgroundColour = "#000000FF"
        this.textColour = "#FFFFFFFF"
      } 
      if (!this.queued) this.load();
    },
    
    methods: {

      queueUpdate(value) {
        this.queued = value
        if (!this.queued) this.load();
      },

      onResize () {
        this.maxImageWidth = (window.innerWidth * 0.9).toFixed(0);
        if (this.maxImageWidth > 600) {
          this.maxImageWidth = 600;
        }
        this.maxProductImageWidth = (window.innerWidth * 0.75).toFixed(0);
        if (this.maxProductImageWidth > 530) {
          this.maxProductImageWidth = 530;
        }
      },

      buyTickets(event) {
        this.$router.push("/boxOffice/" + this.boxOffice.path + "/" + event.path)
      },

      async eventTime(event) {
        var eventTime = {};
        var eventTimes = await Api.post(this, "EventTime", "list", event);
        if (eventTimes && eventTimes.length > 0) {
          eventTime.start = eventTimes[0].start;
          eventTime.end = eventTimes[eventTimes.length-1].end;
        }
        return eventTime;
      },

      async load() {
        this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice, true);
        if (!this.boxOffice) {
          return;
        }
        var content = this.boxOffice.name
        this.meta = {
          title: this.boxOffice.name,
          meta: [
            { vmid: 'description', name: 'description', content: content },
            { name: "title", property: 'og:title', content: content },
            { name: "description", property: 'og:description', content: content },
            { property: 'twitter:title', content: content },
            { property: 'twitter:description', content: content },
          ]
        };
        this.textColour = this.boxOffice.textColour;
        this.backgroundColour = this.boxOffice.backgroundColour;
        this.events = await this.readEvents();
        this.readBackground();
        this.loaded = true;
      },

      async readEvents() {
        var all = await Api.post(this, "Event", "list", this.boxOffice);
        var eventsAndTimes = []
        for (var item of all) {
          item.eventTime = await this.eventTime(item);
          if (item.eventTime.end > Utils.dateNow()) {
            eventsAndTimes.push(item)
          }
        }
        return eventsAndTimes;
      },

      async readBackground() {
        if (this.boxOffice.backgroundImage) {
          this.backgroundImage.id = this.boxOffice.backgroundImage;
          this.backgroundImage = await Api.post(this, "Image", "read", this.backgroundImage);
        }
      },

    },

    computed: {
      
      utils: function () {
        return Utils;
      },

      cssVars() {
        return {
          '--text-color': this.textColour,
          '--background-color': this.backgroundColour,
        }
      },

      styles () {
        const paddingTop = 50
        const paddingBottom = 50
        return {
          padding: `${paddingTop}px 0 ${paddingBottom}px 0`,
        }
      },

    },
  }
</script>

<style lang="sass" scoped>
  .v-card__text, .v-card__title 
    word-break: normal /* maybe !important  */
  .v-sheet.v-card
    color: var(--text-color)
    background-color: var(--background-color)
    border-radius: 0px
  div
    color: var(--text-color)
  .v-btn.v-size--default
    color: var(--text-color)
</style>

